import styled from '@emotion/styled';
import { breakpoints } from '~/theme';
import { Headline } from '../Headline/Headline';

export const StyledCategories = styled.div<{ itemsPerLine?: number }>(
    ({ theme, itemsPerLine }) => ({
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spaces[3],

        [breakpoints.md]: {
            display: 'grid',
            gridTemplateColumns: `repeat(${itemsPerLine}, minmax(0, 1fr))`,
        },
    })
);

export const StyledCategoriesHeader = styled(Headline)<{
    titleAlignment: 'left' | 'center' | 'right';
}>(({ theme, titleAlignment }) => ({
    marginBottom: theme.spaces[3],
    textAlign: titleAlignment,
}));
